export enum Translation {
    ABOUT_MQTT_PREFIX = 'ABOUT_MQTT_PREFIX',
    ABOUT_UI_ACCESS_PERMISSIONS = 'ABOUT_UI_ACCESS_PERMISSIONS',
    ACTION = 'ACTION',
    ACTIVE_ROLE = 'ACTIVE_ROLE',
    ACTIVE_USER = 'ACTIVE_USER',
    ACTUAL = 'ACTUAL',
    ADD = 'ADD',
    ADD_CERTIFICATE = 'ADD_CERTIFICATE',
    ADD_CUSTOM_TOPIC = 'ADD_CUSTOM_TOPIC',
    ADD_PERMISSION = 'ADD_PERMISSION',
    ADD_ROLE = 'ADD_ROLE',
    ADD_USER = 'ADD_USER',
    ADDITIONAL_INFO = 'ADDITIONAL_INFO',
    ADDITIONAL_PERMISSION = 'ADDITIONAL_PERMISSION',
    ADDRESS = 'ADDRESS',
    ADVANCED_MODE = 'ADVANCED_MODE',
    AGENT = 'AGENT',
    AGENT_DOCUMENTATION_BODY = 'AGENT_DOCUMENTATION_BODY',
    AGENT_DOCUMENTATION_TITLE = 'AGENT_DOCUMENTATION_TITLE',
    ALL_RAW_LOGS = 'ALL_RAW_LOGS',
    ALLOW = 'ALLOW',
    ALLOW_ANONYMOUS = 'ALLOW_ANONYMOUS',
    ALTERNATIVE_NAME = 'ALTERNATIVE_NAME',
    APPLICATION_TITLE = 'APPLICATION_TITLE',
    ASK_YOUR_ADMIN_TO_GAIN_ACCESS = 'ASK_YOUR_ADMIN_TO_GAIN_ACCESS',
    AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
    AUTHENTICATION_ERROR_TITLE = 'AUTHENTICATION_ERROR_TITLE',
    AUTHENTICATION_METHODS = 'AUTHENTICATION_METHODS',
    AUTHORIZATION_REQUIRED = 'AUTHORIZATION_REQUIRED',
    AUTHORIZATION_REQUIRED_DOCUMENTATION_BODY = 'AUTHORIZATION_REQUIRED_DOCUMENTATION_BODY',
    AUTHORIZE_ADDITIONAL_USERS_ROLES_PERMISSIONS = 'AUTHORIZE_ADDITIONAL_USERS_ROLES_PERMISSIONS',
    AUTHORIZE_CONNECTIONS = 'AUTHORIZE_CONNECTIONS',
    AUTHORIZE_CONTAINER_DEVICES = 'AUTHORIZE_CONTAINER_DEVICES',
    AUTHORIZE_CONTAINER_PORTS_USAGE = 'AUTHORIZE_CONTAINER_PORTS_USAGE',
    AUTHORIZE_CONTAINERS_START = 'AUTHORIZE_CONTAINERS_START',
    AUTHORIZE_ENDPOINTS = 'AUTHORIZE_ENDPOINTS',
    AUTHORIZE_HTTP_INGRESS_ROUTE_CONTAINER = 'AUTHORIZE_HTTP_INGRESS_ROUTE_CONTAINER',
    AUTHORIZE_HTTP_INGRESS_ROUTE_GENERATED_URL = 'AUTHORIZE_HTTP_INGRESS_ROUTE_GENERATED_URL',
    AUTHORIZE_HTTP_INGRESS_ROUTES = 'AUTHORIZE_HTTP_INGRESS_ROUTES',
    AUTHORIZE_HTTP_INGRESS_ROUTES_SUBTITLE = 'AUTHORIZE_HTTP_INGRESS_ROUTES_SUBTITLE',
    AUTHORIZE_MAPPINGS_EXPLANATION = 'AUTHORIZE_MAPPINGS_EXPLANATION',
    AUTHORIZE_PRIVILEGED_CONTAINER_WARNING = 'AUTHORIZE_PRIVILEGED_CONTAINER_WARNING',
    AUTHORIZE_SERVERS_CREATION = 'AUTHORIZE_SERVERS_CREATION',
    AUTHORIZE_SERVICE_REQUIREMENTS = 'AUTHORIZE_SERVICE_REQUIREMENTS',
    AUTHORIZE_TCP_INGRESS_ROUTE_DESTINATION = 'AUTHORIZE_TCP_INGRESS_ROUTE_DESTINATION',
    AUTHORIZE_TCP_INGRESS_ROUTE_PORT = 'AUTHORIZE_TCP_INGRESS_ROUTE_PORT',
    AUTHORIZE_TCP_INGRESS_ROUTES = 'AUTHORIZE_TCP_INGRESS_ROUTES',
    AUTHORIZE_TCP_INGRESS_ROUTES_SUBTITLE = 'AUTHORIZE_TCP_INGRESS_ROUTES_SUBTITLE',
    AUTHORIZE_VOLUMES_CREATION_EXPLANATION = 'AUTHORIZE_VOLUMES_CREATION_EXPLANATION',
    AVAILABLE_TOPICS = 'AVAILABLE_TOPICS',
    BACK_TO_LOGIN = 'BACK_TO_LOGIN',
    BACKUP_AND_RESTORE_DOCUMENTATION_BODY = 'BACKUP_AND_RESTORE_DOCUMENTATION_BODY',
    BACKUP_AND_RESTORE_DOCUMENTATION_TITLE = 'BACKUP_AND_RESTORE_DOCUMENTATION_TITLE',
    BACKUP_AND_RESTORE_TITLE = 'BACKUP_AND_RESTORE_TITLE',
    BACKUP_CREATED = 'BACKUP_CREATED',
    BACKUP_DESCRIPTION = 'BACKUP_DESCRIPTION',
    BACKUP_FILE_IS_MISSING = 'BACKUP_FILE_IS_MISSING',
    BACKUP_NOT_CREATED = 'BACKUP_NOT_CREATED',
    BACKUP_TITLE = 'BACKUP_TITLE',
    BASIC_CONSTRAINT = 'BASIC_CONSTRAINT',
    BOOLEAN = 'BOOLEAN',
    BROKER = 'BROKER',
    BUFFER_TOO_LARGE_TO_BE_DISPLAYED = 'BUFFER_TOO_LARGE_TO_BE_DISPLAYED',
    BULK_ACTION_CONNECT_CONNECTIONS_ERROR = 'BULK_ACTION_CONNECT_CONNECTIONS_ERROR',
    BULK_ACTION_DELETE_AGENTS_ERROR = 'BULK_ACTION_DELETE_AGENTS_ERROR',
    BULK_ACTION_DELETE_CONNECTIONS_ERROR = 'BULK_ACTION_DELETE_CONNECTIONS_ERROR',
    BULK_ACTION_DELETE_CONTAINERS_ERROR = 'BULK_ACTION_DELETE_CONTAINERS_ERROR',
    BULK_ACTION_DELETE_ENDPOINTS_ERROR = 'BULK_ACTION_DELETE_ENDPOINTS_ERROR',
    BULK_ACTION_DELETE_MAPPINGS_ERROR = 'BULK_ACTION_DELETE_MAPPINGS_ERROR',
    BULK_ACTION_DELETE_SERVERS_ERROR = 'BULK_ACTION_DELETE_SERVERS_ERROR',
    BULK_ACTION_DELETE_SERVICES_ERROR = 'BULK_ACTION_DELETE_SERVICES_ERROR',
    BULK_ACTION_DELETE_VOLUMES_ERROR = 'BULK_ACTION_DELETE_VOLUMES_ERROR',
    BULK_ACTION_DISABLE_CONTAINERS_ERROR = 'BULK_ACTION_DISABLE_CONTAINERS_ERROR',
    BULK_ACTION_DISABLE_ENDPOINTS_ERROR = 'BULK_ACTION_DISABLE_ENDPOINTS_ERROR',
    BULK_ACTION_DISABLE_MAPPINGS_ERROR = 'BULK_ACTION_DISABLE_MAPPINGS_ERROR',
    BULK_ACTION_DISABLE_SERVERS_ERROR = 'BULK_ACTION_DISABLE_SERVERS_ERROR',
    BULK_ACTION_DISABLE_SERVICES_ERROR = 'BULK_ACTION_DISABLE_SERVICES_ERROR',
    BULK_ACTION_DISCONNECT_CONNECTIONS_ERROR = 'BULK_ACTION_DISCONNECT_CONNECTIONS_ERROR',
    BULK_ACTION_ENABLE_CONTAINERS_ERROR = 'BULK_ACTION_ENABLE_CONTAINERS_ERROR',
    BULK_ACTION_ENABLE_ENDPOINTS_ERROR = 'BULK_ACTION_ENABLE_ENDPOINTS_ERROR',
    BULK_ACTION_ENABLE_MAPPINGS_ERROR = 'BULK_ACTION_ENABLE_MAPPINGS_ERROR',
    BULK_ACTION_ENABLE_SERVERS_ERROR = 'BULK_ACTION_ENABLE_SERVERS_ERROR',
    BULK_ACTION_ENABLE_SERVICES_ERROR = 'BULK_ACTION_ENABLE_SERVICES_ERROR',
    BULK_ACTION_RE_ENABLE_CONTAINERS_ERROR = 'BULK_ACTION_RE_ENABLE_CONTAINERS_ERROR',
    BULK_DISABLE_SERVICES_CONFIRMATION = 'BULK_DISABLE_SERVICES_CONFIRMATION',
    CANCEL = 'CANCEL',
    CANNOT_AUTHENTICATE = 'CANNOT_AUTHENTICATE',
    CAPABILITY = 'CAPABILITY',
    CERTIFICATE_AUTHORITY = 'CERTIFICATE_AUTHORITY',
    CERTIFICATE_PROPERTY_NOT_PROVIDED = 'CERTIFICATE_PROPERTY_NOT_PROVIDED',
    CERTIFICATE_VIEWER_TITLE = 'CERTIFICATE_VIEWER_TITLE',
    CERTIFICATES = 'CERTIFICATES',
    CERTIFICATES_DETAILS_DOCUMENTATION_BODY = 'CERTIFICATES_DETAILS_DOCUMENTATION_BODY',
    CERTIFICATES_DETAILS_DOCUMENTATION_TITLE = 'CERTIFICATES_DETAILS_DOCUMENTATION_TITLE',
    CGROUP_PERMISSIONS = 'CGROUP_PERMISSIONS',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_DESCRIPTION = 'CHANGE_PASSWORD_DESCRIPTION',
    CLASS_NAME = 'CLASS_NAME',
    CLIENT_REGISTRATION_FAILED = 'CLIENT_REGISTRATION_FAILED',
    CLIENT_REGISTRY = 'CLIENT_REGISTRY',
    CLIENT_REGISTRY_DOCUMENTATION_BODY = 'CLIENT_REGISTRY_DOCUMENTATION_BODY',
    CLIENT_REGISTRY_DOCUMENTATION_TITLE = 'CLIENT_REGISTRY_DOCUMENTATION_TITLE',
    CLIENT_REGISTRY_NO_REQUESTS = 'CLIENT_REGISTRY_NO_REQUESTS',
    CLIENT_REGISTRY_SELF_REGISTRATION = 'CLIENT_REGISTRY_SELF_REGISTRATION',
    CLOSE = 'CLOSE',
    CNC_TYPE = 'CNC_TYPE',
    COLLECTED_DAILY_METRICS_TITLE = 'COLLECTED_DAILY_METRICS_TITLE',
    COLLECTED_HOURLY_METRICS_TITLE = 'COLLECTED_HOURLY_METRICS_TITLE',
    COMMISSIONING_FILE_PARSING_ERROR = 'COMMISSIONING_FILE_PARSING_ERROR',
    COMMON_NAME = 'COMMON_NAME',
    CONFIGURATION = 'CONFIGURATION',
    CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
    CONNECT = 'CONNECT',
    CONNECT_CONNECTIONS = 'CONNECT_CONNECTIONS',
    CONNECT_CONNECTIONS_SUBTITLE = 'CONNECT_CONNECTIONS_SUBTITLE',
    CONNECTING = 'CONNECTING',
    CONNECTION = 'CONNECTION',
    CONNECTION_DETAILS_DOCUMENTATION_BODY = 'CONNECTION_DETAILS_DOCUMENTATION_BODY',
    CONNECTION_DETAILS_DOCUMENTATION_TITLE = 'CONNECTION_DETAILS_DOCUMENTATION_TITLE',
    CONNECTION_ID = 'CONNECTION_ID',
    CONNECTION_LOGS = 'CONNECTION_LOGS',
    CONNECTION_NOT_FOUND = 'CONNECTION_NOT_FOUND',
    CONNECTIONS_DOCUMENTATION_BODY = 'CONNECTIONS_DOCUMENTATION_BODY',
    CONNECTIONS_DOCUMENTATION_TITLE = 'CONNECTIONS_DOCUMENTATION_TITLE',
    CONNECTWARE_NEW_SELF_HOSTED_APPLIANCE_VERSION = 'CONNECTWARE_NEW_SELF_HOSTED_APPLIANCE_VERSION',
    CONNECTWARE_SLOGAN = 'CONNECTWARE_SLOGAN',
    CONNECTWARE_VERSION = 'CONNECTWARE_VERSION',
    CONNECTWARE_VERSION_INFORMATION = 'CONNECTWARE_VERSION_INFORMATION',
    CONTAINER = 'CONTAINER',
    CONTAINER_DETAILS_DOCUMENTATION_BODY = 'CONTAINER_DETAILS_DOCUMENTATION_BODY',
    CONTAINER_DETAILS_DOCUMENTATION_TITLE = 'CONTAINER_DETAILS_DOCUMENTATION_TITLE',
    CONTAINER_ID = 'CONTAINER_ID',
    CONTAINER_LOGS = 'CONTAINER_LOGS',
    CONTAINER_NOT_FOUND = 'CONTAINER_NOT_FOUND',
    CONTAINERS_DOCUMENTATION_BODY = 'CONTAINERS_DOCUMENTATION_BODY',
    CONTAINERS_DOCUMENTATION_TITLE = 'CONTAINERS_DOCUMENTATION_TITLE',
    CONTEXT = 'CONTEXT',
    COPIED_TO_CLIPBOARD = 'COPIED_TO_CLIPBOARD',
    COPY_LICENSE_ID = 'COPY_LICENSE_ID',
    COPY_PERMISSIONS = 'COPY_PERMISSIONS',
    CORE_CONTAINER_DETAILS_DOCUMENTATION_BODY = 'CORE_CONTAINER_DETAILS_DOCUMENTATION_BODY',
    CORE_CONTAINER_DETAILS_DOCUMENTATION_TITLE = 'CORE_CONTAINER_DETAILS_DOCUMENTATION_TITLE',
    CORE_CONTAINERS_DOCUMENTATION_BODY = 'CORE_CONTAINERS_DOCUMENTATION_BODY',
    CORE_CONTAINERS_DOCUMENTATION_TITLE = 'CORE_CONTAINERS_DOCUMENTATION_TITLE',
    CREATE = 'CREATE',
    CREATE_BACKUP_BUTTON = 'CREATE_BACKUP_BUTTON',
    CREATE_NEW_CATEGORY_SERVICE = 'CREATE_NEW_CATEGORY_SERVICE',
    CREATE_ROLE = 'CREATE_ROLE',
    CREATE_USER = 'CREATE_USER',
    CREATE_YOUR_OWN_SERVICE = 'CREATE_YOUR_OWN_SERVICE',
    CREATED_AT = 'CREATED_AT',
    CREDENTIALS = 'CREDENTIALS',
    CSR_COMMON_NAME_ISSUE = 'CSR_COMMON_NAME_ISSUE',
    CURRENT = 'CURRENT',
    CURRENT_PASSWORD = 'CURRENT_PASSWORD',
    CYBUS_DOCS = 'CYBUS_DOCS',
    CYBUS_LEARN = 'CYBUS_LEARN',
    CYBUS_PORTAL = 'CYBUS_PORTAL',
    CYBUS_PORTAL_STATUS = 'CYBUS_PORTAL_STATUS',
    CYBUS_REGISTRY = 'CYBUS_REGISTRY',
    CYBUS_REGISTRY_STATUS = 'CYBUS_REGISTRY_STATUS',
    DATA = 'DATA',
    DATA_EXPLORER = 'DATA_EXPLORER',
    DATA_EXPLORER_DOCUMENTATION_BODY = 'DATA_EXPLORER_DOCUMENTATION_BODY',
    DATA_EXPLORER_DOCUMENTATION_TITLE = 'DATA_EXPLORER_DOCUMENTATION_TITLE',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    DELETE = 'DELETE',
    DELETE_AGENTS = 'DELETE_AGENTS',
    DELETE_AGENTS_SUBTITLE = 'DELETE_AGENTS_SUBTITLE',
    DELETE_CERTIFICATE_CONFIRMATION = 'DELETE_CERTIFICATE_CONFIRMATION',
    DELETE_CONNECTIONS = 'DELETE_CONNECTIONS',
    DELETE_CONNECTIONS_SUBTITLE = 'DELETE_CONNECTIONS_SUBTITLE',
    DELETE_CONTAINERS = 'DELETE_CONTAINERS',
    DELETE_CONTAINERS_SUBTITLE = 'DELETE_CONTAINERS_SUBTITLE',
    DELETE_ENDPOINTS = 'DELETE_ENDPOINTS',
    DELETE_ENDPOINTS_SUBTITLE = 'DELETE_ENDPOINTS_SUBTITLE',
    DELETE_MAPPINGS = 'DELETE_MAPPINGS',
    DELETE_MAPPINGS_SUBTITLE = 'DELETE_MAPPINGS_SUBTITLE',
    DELETE_ROLE_CONFIRMATION = 'DELETE_ROLE_CONFIRMATION',
    DELETE_SERVERS = 'DELETE_SERVERS',
    DELETE_SERVERS_SUBTITLE = 'DELETE_SERVERS_SUBTITLE',
    DELETE_SERVICES = 'DELETE_SERVICES',
    DELETE_SERVICES_SUBTITLE = 'DELETE_SERVICES_SUBTITLE',
    DELETE_USER_CONFIRMATION = 'DELETE_USER_CONFIRMATION',
    DELETE_VOLUMES = 'DELETE_VOLUMES',
    DELETE_VOLUMES_SUBTITLE = 'DELETE_VOLUMES_SUBTITLE',
    DEPENDENT_SERVICES = 'DEPENDENT_SERVICES',
    DEPENDS_ON_SERVICES = 'DEPENDS_ON_SERVICES',
    DESCRIPTION = 'DESCRIPTION',
    DEVIATION = 'DEVIATION',
    DEVIATION_DETECTED = 'DEVIATION_DETECTED',
    DEVIATION_RESOLVED = 'DEVIATION_RESOLVED',
    DEVIATION_TAB = 'DEVIATION_TAB',
    DEVICE = 'DEVICE',
    DEVICE_ADDRESS = 'DEVICE_ADDRESS',
    DEVICE_INSTANCE = 'DEVICE_INSTANCE',
    DISABLE = 'DISABLE',
    DISABLE_ENDPOINTS = 'DISABLE_ENDPOINTS',
    DISABLE_ENDPOINTS_SUBTITLE = 'DISABLE_ENDPOINTS_SUBTITLE',
    DISABLE_MAPPINGS = 'DISABLE_MAPPINGS',
    DISABLE_MAPPINGS_SUBTITLE = 'DISABLE_MAPPINGS_SUBTITLE',
    DISABLE_SERVERS = 'DISABLE_SERVERS',
    DISABLE_SERVERS_SUBTITLE = 'DISABLE_SERVERS_SUBTITLE',
    DISABLE_SERVICES = 'DISABLE_SERVICES',
    DISABLED = 'DISABLED',
    DISCONNECT = 'DISCONNECT',
    DISCONNECT_CONNECTIONS = 'DISCONNECT_CONNECTIONS',
    DISCONNECT_CONNECTIONS_SUBTITLE = 'DISCONNECT_CONNECTIONS_SUBTITLE',
    DISMISS = 'DISMISS',
    DN_OF_AD_GROUP = 'DN_OF_AD_GROUP',
    DOCKER_HUB = 'DOCKER_HUB',
    DOCKER_HUB_STATUS = 'DOCKER_HUB_STATUS',
    DOCUMENTATION = 'DOCUMENTATION',
    DOWNLOAD = 'DOWNLOAD',
    DOWNLOAD_COMMISSIONING_FILE_DESCRIPTION = 'DOWNLOAD_COMMISSIONING_FILE_DESCRIPTION',
    DOWNLOAD_CSV = 'DOWNLOAD_CSV',
    DOWNLOAD_RAW_LOGS = 'DOWNLOAD_RAW_LOGS',
    DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE',
    EDIT_ROLE = 'EDIT_ROLE',
    EDIT_SERVICE_DOWNLOAD_BUTTON_TEXT = 'EDIT_SERVICE_DOWNLOAD_BUTTON_TEXT',
    EDIT_SERVICE_INVALID_FIELD = 'EDIT_SERVICE_INVALID_FIELD',
    EDIT_SERVICE_INVALID_FIELD_MORE_INFORMATION = 'EDIT_SERVICE_INVALID_FIELD_MORE_INFORMATION',
    EDIT_SERVICE_PAGE_CHECKOUT_DOCUMENTATION = 'EDIT_SERVICE_PAGE_CHECKOUT_DOCUMENTATION',
    EDIT_SERVICE_PAGE_DOCUMENTATION_BODY = 'EDIT_SERVICE_PAGE_DOCUMENTATION_BODY',
    EDIT_SERVICE_PAGE_DOCUMENTATION_TITLE = 'EDIT_SERVICE_PAGE_DOCUMENTATION_TITLE',
    EDIT_SERVICE_PAGE_OUTPUT_INVALID_WARNING = 'EDIT_SERVICE_PAGE_OUTPUT_INVALID_WARNING',
    EDIT_SERVICE_PAGE_OUTPUT_TITLE = 'EDIT_SERVICE_PAGE_OUTPUT_TITLE',
    EDIT_SERVICE_PAGE_TITLE = 'EDIT_SERVICE_PAGE_TITLE',
    EDIT_SERVICE_TEMPLATE = 'EDIT_SERVICE_TEMPLATE',
    EDIT_SERVICE_UNPARSEABLE_COMMISSIONING_FILE = 'EDIT_SERVICE_UNPARSEABLE_COMMISSIONING_FILE',
    EDIT_SERVICE_UPLOAD_TEMPLATE = 'EDIT_SERVICE_UPLOAD_TEMPLATE',
    EDIT_USER = 'EDIT_USER',
    ENABLE = 'ENABLE',
    ENABLE_ENDPOINTS = 'ENABLE_ENDPOINTS',
    ENABLE_ENDPOINTS_SUBTITLE = 'ENABLE_ENDPOINTS_SUBTITLE',
    ENABLE_MAPPINGS = 'ENABLE_MAPPINGS',
    ENABLE_MAPPINGS_SUBTITLE = 'ENABLE_MAPPINGS_SUBTITLE',
    ENABLE_SERVERS = 'ENABLE_SERVERS',
    ENABLE_SERVERS_SUBTITLE = 'ENABLE_SERVERS_SUBTITLE',
    ENABLING = 'ENABLING',
    END_TIME = 'END_TIME',
    ENDPOINT = 'ENDPOINT',
    ENDPOINT_DETAILS_DOCUMENTATION_BODY = 'ENDPOINT_DETAILS_DOCUMENTATION_BODY',
    ENDPOINT_DETAILS_DOCUMENTATION_TITLE = 'ENDPOINT_DETAILS_DOCUMENTATION_TITLE',
    ENDPOINT_LOGS = 'ENDPOINT_LOGS',
    ENDPOINT_NOT_FOUND = 'ENDPOINT_NOT_FOUND',
    ENDPOINTS_DOCUMENTATION_BODY = 'ENDPOINTS_DOCUMENTATION_BODY',
    ENDPOINTS_DOCUMENTATION_TITLE = 'ENDPOINTS_DOCUMENTATION_TITLE',
    ENFORCE_MFA_USAGE = 'ENFORCE_MFA_USAGE',
    ENTRA_OBJECT_ID = 'ENTRA_OBJECT_ID',
    ENTRY = 'ENTRY',
    ENVIRONMENT_VARIABLE = 'ENVIRONMENT_VARIABLE',
    ERROR = 'ERROR',
    ERROR_EXTRA_INFORMATION = 'ERROR_EXTRA_INFORMATION',
    ERROR_TITLE = 'ERROR_TITLE',
    ERROR_WAS_ENCOUNTERED = 'ERROR_WAS_ENCOUNTERED',
    EXPECTED = 'EXPECTED',
    EXPIRATION_DATE = 'EXPIRATION_DATE',
    EXPIRES_ON = 'EXPIRES_ON',
    EXTENDED_KEY_USAGE = 'EXTENDED_KEY_USAGE',
    EXTENDED_KEY_USAGE_TYPE = 'EXTENDED_KEY_USAGE_TYPE',
    EXTENSIONS = 'EXTENSIONS',
    EXTERNAL = 'EXTERNAL',
    FILE_UPLOAD = 'FILE_UPLOAD',
    FILE_UPLOAD_LABEL = 'FILE_UPLOAD_LABEL',
    FILTER = 'FILTER',
    FINGERPRINT = 'FINGERPRINT',
    FROM = 'FROM',
    GO_TO = 'GO_TO',
    GRANT_ACCESS = 'GRANT_ACCESS',
    HELP = 'HELP',
    HIDE_LICENSE_ID = 'HIDE_LICENSE_ID',
    HISTORY = 'HISTORY',
    HOMEPAGE = 'HOMEPAGE',
    HOST = 'HOST',
    HOSTNAME = 'HOSTNAME',
    HOW_TO = 'HOW_TO',
    INFO = 'INFO',
    INFO_PAGE_DOCUMENTATION_BODY = 'INFO_PAGE_DOCUMENTATION_BODY',
    INFO_PAGE_DOCUMENTATION_TITLE = 'INFO_PAGE_DOCUMENTATION_TITLE',
    INHERITED_FROM = 'INHERITED_FROM',
    INPUT = 'INPUT',
    INPUT_MQTT_TOPIC = 'INPUT_MQTT_TOPIC',
    INPUT_TRANSFORMATION_RULE = 'INPUT_TRANSFORMATION_RULE',
    INSTALL = 'INSTALL',
    INSTALLED_SERVICES = 'INSTALLED_SERVICES',
    INTERNAL = 'INTERNAL',
    INTERNET_CONNECTIVITY = 'INTERNET_CONNECTIVITY',
    INTERNET_CONNECTIVITY_DOCUMENTATION_BODY = 'INTERNET_CONNECTIVITY_DOCUMENTATION_BODY',
    INTERNET_CONNECTIVITY_DOCUMENTATION_TITLE = 'INTERNET_CONNECTIVITY_DOCUMENTATION_TITLE',
    INVALID_CSR = 'INVALID_CSR',
    INVALID_OTP = 'INVALID_OTP',
    INVALID_SERVICE_ID = 'INVALID_SERVICE_ID',
    IP_ADDRESS = 'IP_ADDRESS',
    IS_URL_REACHABLE = 'IS_URL_REACHABLE',
    IS_USED_BY = 'IS_USED_BY',
    ISSUED_BY = 'ISSUED_BY',
    ISSUED_ON = 'ISSUED_ON',
    ISSUED_TO = 'ISSUED_TO',
    JSON = 'JSON',
    JUMP_TO_PAGE = 'JUMP_TO_PAGE',
    KEY_USAGE = 'KEY_USAGE',
    KEY_USAGE_TYPE = 'KEY_USAGE_TYPE',
    LABEL = 'LABEL',
    LAST_SEEN = 'LAST_SEEN',
    LAST_UPDATED = 'LAST_UPDATED',
    LEVEL = 'LEVEL',
    LICENSE = 'LICENSE',
    LICENSE_ACCOUNT = 'LICENSE_ACCOUNT',
    LICENSE_ACTIVATION_NEEDED = 'LICENSE_ACTIVATION_NEEDED',
    LICENSE_ACTIVATION_NEEDED_DESCRIPTION = 'LICENSE_ACTIVATION_NEEDED_DESCRIPTION',
    LICENSE_ACTIVATION_TITLE = 'LICENSE_ACTIVATION_TITLE',
    LICENSE_CLASS = 'LICENSE_CLASS',
    LICENSE_ERROR_SUMMARY = 'LICENSE_ERROR_SUMMARY',
    LICENSE_EXPIRED_CONTACT_SALES = 'LICENSE_EXPIRED_CONTACT_SALES',
    LICENSE_EXPIRED_EXTENSION_NEEDED = 'LICENSE_EXPIRED_EXTENSION_NEEDED',
    LICENSE_ID = 'LICENSE_ID',
    LICENSE_MODAL_FOOTER = 'LICENSE_MODAL_FOOTER',
    LICENSE_MODAL_TITLE = 'LICENSE_MODAL_TITLE',
    LICENSE_NAME = 'LICENSE_NAME',
    LICENSE_OFFLINE_ACTIVATION = 'LICENSE_OFFLINE_ACTIVATION',
    LICENSE_ONLINE_ACTIVATION = 'LICENSE_ONLINE_ACTIVATION',
    LICENSE_ONLINE_AVAILABILITY_CHECK = 'LICENSE_ONLINE_AVAILABILITY_CHECK',
    LICENSE_REFRESH_REQUIRES_INTERNET = 'LICENSE_REFRESH_REQUIRES_INTERNET',
    LICENSE_STATUS = 'LICENSE_STATUS',
    LICENSE_STILL_INVALID = 'LICENSE_STILL_INVALID',
    LICENSE_VALIDATION_ERROR = 'LICENSE_VALIDATION_ERROR',
    LINK = 'LINK',
    LIVE_DATA_SUBSCRIPTION_ERROR = 'LIVE_DATA_SUBSCRIPTION_ERROR',
    LOCAL_INTERFACE = 'LOCAL_INTERFACE',
    LOCK = 'LOCK',
    LOG_DETAILS = 'LOG_DETAILS',
    LOG_LEVEL = 'LOG_LEVEL',
    LOGICAL_ID = 'LOGICAL_ID',
    LOGIN_FORM_BACKUP_CODE = 'LOGIN_FORM_BACKUP_CODE',
    LOGIN_FORM_CANCEL_MFA = 'LOGIN_FORM_CANCEL_MFA',
    LOGIN_FORM_MFA_EXPLANATION = 'LOGIN_FORM_MFA_EXPLANATION',
    LOGIN_FORM_TITLE = 'LOGIN_FORM_TITLE',
    LOGIN_SETTINGS = 'LOGIN_SETTINGS',
    LOGIN_SETTINGS_DOCUMENTATION_BODY = 'LOGIN_SETTINGS_DOCUMENTATION_BODY',
    LOGIN_SETTINGS_DOCUMENTATION_TITLE = 'LOGIN_SETTINGS_DOCUMENTATION_TITLE',
    LOGOUT = 'LOGOUT',
    MAINTENANCE = 'MAINTENANCE',
    MAINTENANCE_DESCRIPTION = 'MAINTENANCE_DESCRIPTION',
    MAPPING = 'MAPPING',
    MAPPING_DETAILS_DOCUMENTATION_BODY = 'MAPPING_DETAILS_DOCUMENTATION_BODY',
    MAPPING_DETAILS_DOCUMENTATION_TITLE = 'MAPPING_DETAILS_DOCUMENTATION_TITLE',
    MAPPING_ID = 'MAPPING_ID',
    MAPPING_LOGS = 'MAPPING_LOGS',
    MAPPING_NOT_FOUND = 'MAPPING_NOT_FOUND',
    MAPPINGS_DOCUMENTATION_BODY = 'MAPPINGS_DOCUMENTATION_BODY',
    MAPPINGS_DOCUMENTATION_TITLE = 'MAPPINGS_DOCUMENTATION_TITLE',
    MESSAGE = 'MESSAGE',
    MESSAGE_PER_DAY = 'MESSAGE_PER_DAY',
    MESSAGE_PER_HOUR = 'MESSAGE_PER_HOUR',
    METADATA = 'METADATA',
    METRICS = 'METRICS',
    METRICS_DOCUMENTATION_BODY = 'METRICS_DOCUMENTATION_BODY',
    METRICS_DOCUMENTATION_TITLE = 'METRICS_DOCUMENTATION_TITLE',
    MFA_ALREADY_ENABLED_ERROR = 'MFA_ALREADY_ENABLED_ERROR',
    MFA_BACKUP_CODES_PRESENTATION_SUBTITLE = 'MFA_BACKUP_CODES_PRESENTATION_SUBTITLE',
    MFA_BACKUP_CODES_PRESENTATION_TITLE = 'MFA_BACKUP_CODES_PRESENTATION_TITLE',
    MFA_ENABLED_ACTION_CONFIRMATION = 'MFA_ENABLED_ACTION_CONFIRMATION',
    MFA_ENABLED_ACTION_DESCRIPTION = 'MFA_ENABLED_ACTION_DESCRIPTION',
    MFA_ENABLING_STEPS_SUBTITLE = 'MFA_ENABLING_STEPS_SUBTITLE',
    MFA_ENABLING_STEPS_TITLE = 'MFA_ENABLING_STEPS_TITLE',
    MFA_ENFORCED_NOTIFICATION = 'MFA_ENFORCED_NOTIFICATION',
    MFA_MFA_SECRET_KEY_LABEL = 'MFA_SECRET_KEY_LABEL',
    MFA_MISSING_PERMISSIONS_ERROR = 'MFA_MISSING_PERMISSIONS_ERROR',
    MFA_SAVE_CONFIGURATION = 'MFA_SAVE_CONFIGURATION',
    MFA_SETTINGS_SUBTITLE = 'MFA_SETTINGS_SUBTITLE',
    MFA_TOGGLE = 'MFA_TOGGLE',
    MISSING_READ_OR_WRITE_PERMISSION = 'MISSING_READ_OR_WRITE_PERMISSION',
    MONITORED_TOPICS = 'MONITORED_TOPICS',
    MORE = 'MORE',
    MQTT_COULD_NOT_CONNECT_ERROR = 'MQTT_COULD_NOT_CONNECT_ERROR',
    MQTT_GENERIC_ERROR = 'MQTT_GENERIC_ERROR',
    MQTT_PUBLISH_PREFIX = 'MQTT_PUBLISH_PREFIX',
    MQTT_PUBLISH_PREFIX_VALIDATION = 'MQTT_PUBLISH_PREFIX_VALIDATION',
    MQTT_PUBLISH_PREFIX_WARNING = 'MQTT_PUBLISH_PREFIX_WARNING',
    MQTT_TOPIC = 'MQTT_TOPIC',
    MULTI_FACTOR_AUTHENTICATION = 'MULTI_FACTOR_AUTHENTICATION',
    MULTI_FACTOR_AUTHENTICATION_ACRONYM = 'MULTI_FACTOR_AUTHENTICATION_ACRONYM',
    NAME = 'NAME',
    NAVIGATION_CERTIFICATES_DESCRIPTION = 'NAVIGATION_CERTIFICATES_DESCRIPTION',
    NAVIGATION_CLIENT_REGISTRY_DESCRIPTION = 'NAVIGATION_CLIENT_REGISTRY_DESCRIPTION',
    NAVIGATION_CONNECTIONS_DESCRIPTION = 'NAVIGATION_CONNECTIONS_DESCRIPTION',
    NAVIGATION_CONTAINERS_DESCRIPTION = 'NAVIGATION_CONTAINERS_DESCRIPTION',
    NAVIGATION_ENDPOINTS_DESCRIPTION = 'NAVIGATION_ENDPOINTS_DESCRIPTION',
    NAVIGATION_EXPLORER_DESCRIPTION = 'NAVIGATION_EXPLORER_DESCRIPTION',
    NAVIGATION_MAPPINGS_DESCRIPTION = 'NAVIGATION_MAPPINGS_DESCRIPTION',
    NAVIGATION_RULE_ENGINE_SANDBOX_DESCRIPTION = 'NAVIGATION_RULE_ENGINE_SANDBOX_DESCRIPTION',
    NAVIGATION_SERVERS_DESCRIPTION = 'NAVIGATION_SERVERS_DESCRIPTION',
    NAVIGATION_SERVICES_CATALOG_DESCRIPTION = 'NAVIGATION_SERVICES_CATALOG_DESCRIPTION',
    NAVIGATION_SERVICES_DESCRIPTION = 'NAVIGATION_SERVICES_DESCRIPTION',
    NAVIGATION_SERVICES_LINKS_DESCRIPTION = 'NAVIGATION_SERVICES_LINKS_DESCRIPTION',
    NAVIGATION_SYSTEM_AGENTS_DESCRIPTION = 'NAVIGATION_SYSTEM_AGENTS_DESCRIPTION',
    NAVIGATION_SYSTEM_LICENSE_DESCRIPTION = 'NAVIGATION_SYSTEM_LICENSE_DESCRIPTION',
    NAVIGATION_SYSTEM_METRICS_DESCRIPTION = 'NAVIGATION_SYSTEM_METRICS_DESCRIPTION',
    NAVIGATION_SYSTEM_STATUSES_DESCRIPTION = 'NAVIGATION_SYSTEM_STATUSES_DESCRIPTION',
    NAVIGATION_VOLUMES_DESCRIPTION = 'NAVIGATION_VOLUMES_DESCRIPTION',
    NEW = 'NEW',
    NEW_PASSWORD = 'NEW_PASSWORD',
    NO_AGENTS_AVAILABLE = 'NO_AGENTS_AVAILABLE',
    NO_AGENTS_RUNNING = 'NO_AGENTS_RUNNING',
    NO_CATALOG_SERVICES = 'NO_CATALOG_SERVICES',
    NO_CATEGORY_SERVICES_FOUND = 'NO_CATEGORY_SERVICES_FOUND',
    NO_CERTIFICATES = 'NO_CERTIFICATES',
    NO_CHANGE_PASSWORD_PERMISSION_DESCRIPTION = 'NO_CHANGE_PASSWORD_PERMISSION_DESCRIPTION',
    NO_CONNECTIONS = 'NO_CONNECTIONS',
    NO_CONNECTIONS_AVAILABLE = 'NO_CONNECTIONS_AVAILABLE',
    NO_CONTAINERS_AVAILABLE = 'NO_CONTAINERS_AVAILABLE',
    NO_ENDPOINTS = 'NO_ENDPOINTS',
    NO_ENDPOINTS_AVAILABLE = 'NO_ENDPOINTS_AVAILABLE',
    NO_ENTRIES_AVAILABLE = 'NO_ENTRIES_AVAILABLE',
    NO_LIVE_DATA_RESOURCES = 'NO_LIVE_DATA_RESOURCES',
    NO_LIVE_DATA_RESOURCES_FOUND = 'NO_LIVE_DATA_RESOURCES_FOUND',
    NO_LOGS_FOUND = 'NO_LOGS_FOUND',
    NO_MAPPINGS_AVAILABLE = 'NO_MAPPINGS_AVAILABLE',
    NO_MESSAGES = 'NO_MESSAGES',
    NO_METRICS = 'NO_METRICS',
    NO_NODES_AVAILABLE = 'NO_NODES_AVAILABLE',
    NO_PERMISSIONS_FOUND = 'NO_PERMISSIONS_FOUND',
    NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
    NO_ROLES = 'NO_ROLES',
    NO_ROLES_FOUND = 'NO_ROLES_FOUND',
    NO_SERVERS_AVAILABLE = 'NO_SERVERS_AVAILABLE',
    NO_SERVICE_LINKS_AVAILABLE = 'NO_SERVICE_LINKS_AVAILABLE',
    NO_SERVICES_INSTALLED = 'NO_SERVICES_INSTALLED',
    NO_TEMPLATES_AVAILABLE = 'NO_TEMPLATES_AVAILABLE',
    NO_TOPICS_AVAILABLE = 'NO_TOPICS_AVAILABLE',
    NO_TOPICS_SELECTED = 'NO_TOPICS_SELECTED',
    NO_USERS_FOUND = 'NO_USERS_FOUND',
    NO_VOLUMES_AVAILABLE = 'NO_VOLUMES_AVAILABLE',
    NODE = 'NODE',
    NONE = 'NONE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    NOT_EDITABLE = 'NOT_EDITABLE',
    NOTHING_TO_CONFIGURE = 'NOTHING_TO_CONFIGURE',
    NUMBER_OF_DATAPOINTS = 'NUMBER_OF_DATAPOINTS',
    NUMBER_OF_MESSAGES_PER_DAY = 'NUMBER_OF_MESSAGES_PER_DAY',
    NUMBER_OF_MESSAGES_PER_HOUR = 'NUMBER_OF_MESSAGES_PER_HOUR',
    NUMBER_OF_VOLUMES = 'NUMBER_OF_VOLUMES',
    OPEN_DOCUMENTATION = 'OPEN_DOCUMENTATION',
    OPERATION = 'OPERATION',
    OPERATION_PERMISSION = 'OPERATION_PERMISSION',
    OR_USE_USERNAME = 'OR_USE_USERNAME',
    ORGANIZATION = 'ORGANIZATION',
    ORGANTIZATION_UNIT = 'ORGANTIZATION_UNIT',
    PARENT = 'PARENT',
    PASSWORD = 'PASSWORD',
    PASSWORD_SUCCESSFULLY_CHANGED = 'PASSWORD_SUCCESSFULLY_CHANGED',
    PASSWORD_VALIDATION = 'PASSWORD_VALIDATION',
    PATH = 'PATH',
    PATH_IN_CONTAINER = 'PATH_IN_CONTAINER',
    PATH_LENGTH = 'PATH_LENGTH',
    PATH_ON_HOST = 'PATH_ON_HOST',
    PERMISSION = 'PERMISSION',
    PERMISSION_ALREADY_EXISTS = 'PERMISSION_ALREADY_EXISTS',
    PERMISSIONS_CHANGED_NOTIFICATION = 'PERMISSIONS_CHANGED_NOTIFICATION',
    PID = 'PID',
    PLEASE_CONTACT_YOUR_CYBUS_ADMINISTRATOR = 'PLEASE_CONTACT_YOUR_CYBUS_ADMINISTRATOR',
    PORT = 'PORT',
    PRIVILEGED = 'PRIVILEGED',
    PROBLEM_CHANGING_PASSWORD = 'PROBLEM_CHANGING_PASSWORD',
    PROTOCOL = 'PROTOCOL',
    PROVIDER = 'PROVIDER',
    PUBLISH = 'PUBLISH',
    PUBLISH_BROKER = 'PUBLISH_BROKER',
    REASON = 'REASON',
    REDUCED_PERMISSIONS_TITLE = 'REDUCED_PERMISSIONS_TITLE',
    REFRESH_LICENSE = 'REFRESH_LICENSE',
    REFRESH_LICENSE_FILE_FROM_PORTAL = 'REFRESH_LICENSE_FILE_FROM_PORTAL',
    REFRESH_LOGS = 'REFRESH_LOGS',
    RELOAD_SESSION = 'RELOAD_SESSION',
    REMOVE = 'REMOVE',
    REMOVE_ALL_PERMISSIONS = 'REMOVE_ALL_PERMISSIONS',
    REMOVE_CUSTOMIZATION = 'REMOVE_CUSTOMIZATION',
    RENEW_BACKUP = 'RENEW_BACKUP',
    REQUEST_SERVICE = 'REQUEST_SERVICE',
    REQUEST_SERVICE_SUBJECT = 'REQUEST_SERVICE_SUBJECT',
    REQUIRED_PERMISSION = 'REQUIRED_PERMISSION',
    RESET = 'RESET',
    RESOURCE = 'RESOURCE',
    RESOURCE_ID = 'RESOURCE_ID',
    RESOURCE_IS_MISSING = 'RESOURCE_IS_MISSING',
    RESOURCE_TYPE = 'RESOURCE_TYPE',
    RESOURCES_AND_SERVICE_LINK_DOCUMENTATION_BODY = 'RESOURCES_AND_SERVICE_LINK_DOCUMENTATION_BODY',
    RESOURCES_AND_SERVICE_LINK_DOCUMENTATION_TITLE = 'RESOURCES_AND_SERVICE_LINK_DOCUMENTATION_TITLE',
    RESTART = 'RESTART',
    RESTART_CONTAINERS = 'RESTART_CONTAINERS',
    RESTART_CONTAINERS_SUBTITLE = 'RESTART_CONTAINERS_SUBTITLE',
    RESTORE = 'RESTORE',
    RESTORE_BACKUP_BUTTON = 'RESTORE_BACKUP_BUTTON',
    RESTORE_COMPLETE = 'RESTORE_COMPLETE',
    RESTORE_DESCRIPTION = 'RESTORE_DESCRIPTION',
    RESTORED = 'RESTORED',
    ROLE = 'ROLE',
    ROLE_NAME = 'ROLE_NAME',
    ROLE_NAME_ALREADY_USED = 'ROLE_NAME_ALREADY_USED',
    ROWS_PER_PAGE = 'ROWS_PER_PAGE',
    RULE = 'RULE',
    RULE_ENGINE_SANDBOX = 'RULE_ENGINE_SANDBOX',
    RULE_ENGINE_SANDBOX_DOCUMENTATION_BODY = 'RULE_ENGINE_SANDBOX_DOCUMENTATION_BODY',
    RULE_ENGINE_SANDBOX_DOCUMENTATION_TITLE = 'RULE_ENGINE_SANDBOX_DOCUMENTATION_TITLE',
    RULE_ENGINE_SANDBOX_TRANSFORMATION_PLACEHOLDER = 'RULE_ENGINE_SANDBOX_TRANSFORMATION_PLACEHOLDER',
    RULE_ERROR = 'RULE_ERROR',
    SANDBOX_SUBSCRIPTION_ISSUE = 'SANDBOX_SUBSCRIPTION_ISSUE',
    SAVE_NEW_PASSWORD = 'SAVE_NEW_PASSWORD',
    SEARCH = 'SEARCH',
    SEARCH_FOR_SERVICES = 'SEARCH_FOR_SERVICES',
    SELECT_ENDPOINT = 'SELECT_ENDPOINT',
    SELECT_TRANSFORMATION_SOURCE = 'SELECT_TRANSFORMATION_SOURCE',
    SEND_METRICS_TO_PORTAL = 'SEND_METRICS_TO_PORTAL',
    SERVER = 'SERVER',
    SERVER_DETAILS_DOCUMENTATION_BODY = 'SERVER_DETAILS_DOCUMENTATION_BODY',
    SERVER_DETAILS_DOCUMENTATION_TITLE = 'SERVER_DETAILS_DOCUMENTATION_TITLE',
    SERVER_ERROR = 'SERVER_ERROR',
    SERVER_LOGS = 'SERVER_LOGS',
    SERVER_NOT_FOUND = 'SERVER_NOT_FOUND',
    SERVERS_DOCUMENTATION_BODY = 'SERVERS_DOCUMENTATION_BODY',
    SERVERS_DOCUMENTATION_TITLE = 'SERVERS_DOCUMENTATION_TITLE',
    SERVICE = 'SERVICE',
    SERVICE_CATEGORY = 'SERVICE_CATEGORY',
    SERVICE_COMMISSIONING_FILE_LABEL = 'SERVICE_COMMISSIONING_FILE_LABEL',
    SERVICE_CONFIGURE_CURRENT = 'SERVICE_CONFIGURE_CURRENT',
    SERVICE_CONFIGURE_HINT = 'SERVICE_CONFIGURE_HINT',
    SERVICE_CONFIGURE_NEW = 'SERVICE_CONFIGURE_NEW',
    SERVICE_CREATE = 'SERVICE_CREATE',
    SERVICE_CREATION_GENERIC_ERROR = 'SERVICE_CREATION_GENERIC_ERROR',
    SERVICE_DETAILS = 'SERVICE_DETAILS',
    SERVICE_DETAILS_DOCUMENTATION_BODY = 'SERVICE_DETAILS_DOCUMENTATION_BODY',
    SERVICE_DETAILS_DOCUMENTATION_TITLE = 'SERVICE_DETAILS_DOCUMENTATION_TITLE',
    SERVICE_DOCUMENTATION_LINK = 'SERVICE_DOCUMENTATION_LINK',
    SERVICE_ID = 'SERVICE_ID',
    SERVICE_ID_ALREADY_USED = 'SERVICE_ID_ALREADY_USED',
    SERVICE_LINK = 'SERVICE_LINK',
    SERVICE_LIVE_DATA = 'SERVICE_LIVE_DATA',
    SERVICE_LOGS = 'SERVICE_LOGS',
    SERVICE_LOGS_DOCUMENTATION_BODY = 'SERVICE_LOGS_DOCUMENTATION_BODY',
    SERVICE_LOGS_DOCUMENTATION_TITLE = 'SERVICE_LOGS_DOCUMENTATION_TITLE',
    SERVICE_NOT_FOUND = 'SERVICE_NOT_FOUND',
    SERVICE_OVERVIEW = 'SERVICE_OVERVIEW',
    SERVICE_RESOURCE_TYPE = 'SERVICE_RESOURCE_TYPE',
    SERVICE_RESOURCES = 'SERVICE_RESOURCES',
    SERVICE_SELECT_COMMISSIONING_FILE = 'SERVICE_SELECT_COMMISSIONING_FILE',
    SERVICE_UPDATE_AVAILABLE = 'SERVICE_UPDATE_AVAILABLE',
    SERVICE_UPDATE_FROM_CATALOG = 'SERVICE_UPDATE_FROM_CATALOG',
    SERVICE_UPDATE_NOT_AVAILABLE = 'SERVICE_UPDATE_NOT_AVAILABLE',
    SERVICE_UPDATE_RECONFIGURE = 'SERVICE_UPDATE_RECONFIGURE',
    SERVICES_CATALOG = 'SERVICES_CATALOG',
    SERVICES_CATALOG_DOCUMENTATION_BODY = 'SERVICES_CATALOG_DOCUMENTATION_BODY',
    SERVICES_CATALOG_DOCUMENTATION_TITLE = 'SERVICES_CATALOG_DOCUMENTATION_TITLE',
    SERVICES_DETAILS_DOCUMENTATION_BODY = 'SERVICES_DETAILS_DOCUMENTATION_BODY',
    SERVICES_DETAILS_DOCUMENTATION_TITLE = 'SERVICES_DETAILS_DOCUMENTATION_TITLE',
    SETTINGS = 'SETTINGS',
    SHOW_LICENSE_ID = 'SHOW_LICENSE_ID',
    SHOW_THE_NEXT = 'SHOW_THE_NEXT',
    SHOW_USER_MANAGEMENT_RESULTS = 'SHOW_USER_MANAGEMENT_RESULTS',
    SIGN_IN = 'SIGN_IN',
    SIGN_IN_WITH_SSO = 'SIGN_IN_WITH_SSO',
    SSO_INVALID_CLIENT_SECRET = 'SSO_INVALID_CLIENT_SECRET',
    SSO_REDIRECTING_ERROR = 'SSO_REDIRECTING_ERROR',
    SSO_STATUS_FETCHING_ERROR = 'SSO_STATUS_FETCHING_ERROR',
    START = 'START',
    START_CONTAINERS = 'START_CONTAINERS',
    START_CONTAINERS_SUBTITLE = 'START_CONTAINERS_SUBTITLE',
    START_TIME = 'START_TIME',
    STATUS = 'STATUS',
    STATUS_LABEL = 'STATUS_LABEL',
    STOP = 'STOP',
    STOP_CONTAINERS = 'STOP_CONTAINERS',
    STOP_CONTAINERS_SUBTITLE = 'STOP_CONTAINERS_SUBTITLE',
    SUBSCRIBE = 'SUBSCRIBE',
    SUBSCRIBE_BROKER = 'SUBSCRIBE_BROKER',
    SUCCESSFULLY_SENT_METRICS_TO_PORTAL = 'SUCCESSFULLY_SENT_METRICS_TO_PORTAL',
    SYSTEM = 'SYSTEM',
    SYSTEM_CONTAINER = 'SYSTEM_CONTAINER',
    SYSTEM_INFORMATION = 'SYSTEM_INFORMATION',
    SYSTEM_INFORMATION_DOCUMENTATION_BODY = 'SYSTEM_INFORMATION_DOCUMENTATION_BODY',
    SYSTEM_INFORMATION_DOCUMENTATION_TITLE = 'SYSTEM_INFORMATION_DOCUMENTATION_TITLE',
    SYSTEM_STATUS = 'SYSTEM_STATUS',
    TAIL_SIZE = 'TAIL_SIZE',
    TEMPLATE_COMMISSIONING_FILE_DOWNLOAD_HOW_TO = 'TEMPLATE_COMMISSIONING_FILE_DOWNLOAD_HOW_TO',
    TEMPLATES_FROM_CATEGORY = 'TEMPLATES_FROM_CATEGORY',
    TIME = 'TIME',
    TIMEOUT_ERROR = 'TIMEOUT_ERROR',
    TIMESTAMP = 'TIMESTAMP',
    TOGGLE_AUTHENTICATION_METHOD = 'TOGGLE_AUTHENTICATION_METHOD',
    TOKEN_ERROR_DIALOG_CONTENT = 'TOKEN_ERROR_DIALOG_CONTENT',
    TOKEN_ERROR_DIALOG_TITLE = 'TOKEN_ERROR_DIALOG_TITLE',
    TOKEN_EXPIRED_ERROR = 'TOKEN_EXPIRED_ERROR',
    TOPIC = 'TOPIC',
    TOPIC_PLACEHOLDER = 'TOPIC_PLACEHOLDER',
    TOPIC_SUBSCRIPTION_ERROR = 'TOPIC_SUBSCRIPTION_ERROR',
    TOPIC_TYPE = 'TOPIC_TYPE',
    TOPICS_ENDPOINT_SERVICE_UNAVAILABLE_ERROR = 'TOPICS_ENDPOINT_SERVICE_UNAVAILABLE_ERROR',
    TOPICS_FETCHING_ERROR = 'TOPICS_FETCHING_ERROR',
    TOPICS_METADATA_PERMISSION_MISSING = 'TOPICS_METADATA_PERMISSION_MISSING',
    TOPICS_SOURCE = 'TOPICS_SOURCE',
    TOTAL_PERMISSIONS = 'TOTAL_PERMISSIONS',
    TRANSFORMATION_INPUT = 'TRANSFORMATION_INPUT',
    TRANSFORMATION_INPUT_HELPER_TEXT = 'TRANSFORMATION_INPUT_HELPER_TEXT',
    TRANSFORMATION_RESULT = 'TRANSFORMATION_RESULT',
    TRANSFORMATION_RULE = 'TRANSFORMATION_RULE',
    TYPE = 'TYPE',
    UNLOCK = 'UNLOCK',
    UNSUB = 'UNSUB',
    UPDATE = 'UPDATE',
    UPLOAD_LICENSE_FILE = 'UPLOAD_LICENSE_FILE',
    UPLOAD_SERVICE = 'UPLOAD_SERVICE',
    USE_VERSION = 'USE_VERSION',
    USER = 'USER',
    USER_AUTHENTICATION_METHOD = 'USER_AUTHENTICATION_METHOD',
    USER_CERTIFICATES = 'USER_CERTIFICATES',
    USER_MANAGEMENT = 'USER_MANAGEMENT',
    USER_NAME_VALIDATION = 'USER_NAME_VALIDATION',
    USER_PROVIDER = 'USER_PROVIDER',
    USER_UPDATE_ERROR_PROTECTED_USER = 'USER_UPDATE_ERROR_PROTECTED_USER',
    USERNAME = 'USERNAME',
    USERNAME_ALREADY_USED = 'USERNAME_ALREADY_USED',
    USERS_AND_ROLES = 'USERS_AND_ROLES',
    USERS_AND_ROLES_DOCUMENTATION_BODY = 'USERS_AND_ROLES_DOCUMENTATION_BODY',
    USERS_AND_ROLES_DOCUMENTATION_TITLE = 'USERS_AND_ROLES_DOCUMENTATION_TITLE',
    VALIDITY_PERIOD = 'VALIDITY_PERIOD',
    VALUE = 'VALUE',
    VERSION = 'VERSION',
    VERSION_MISMATCH_BODY = 'VERSION_MISMATCH_BODY',
    VERSION_MISMATCH_TITLE = 'VERSION_MISMATCH_TITLE',
    VOLUME = 'VOLUME',
    VOLUME_DETAILS_DOCUMENTATION_BODY = 'VOLUME_DETAILS_DOCUMENTATION_BODY',
    VOLUME_DETAILS_DOCUMENTATION_TITLE = 'VOLUME_DETAILS_DOCUMENTATION_TITLE',
    VOLUME_LOGS = 'VOLUME_LOGS',
    VOLUME_NOT_FOUND = 'VOLUME_NOT_FOUND',
    VOLUMES_DOCUMENTATION_BODY = 'VOLUMES_DOCUMENTATION_BODY',
    VOLUMES_DOCUMENTATION_TITLE = 'VOLUMES_DOCUMENTATION_TITLE',
    WORKBENCH_TITLE = 'WORKBENCH_TITLE',
    WRONG_MFA_CREDENTAILS = 'WRONG_MFA_CREDENTAILS',
    YOU_NEED_PERMISSION_TO_VIEW_THIS_DATA = 'YOU_NEED_PERMISSION_TO_VIEW_THIS_DATA',
    YOUR_SEARCH_FOUND_NO_SERVICES = 'YOUR_SEARCH_FOUND_NO_SERVICES',
}
