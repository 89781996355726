import type { PickByValueExact, ValuesType } from 'utility-types'

import { Capability, ConnectwareError, ConnectwareErrorType, type StatusType } from '../../../../../../domain'
import type { SubscriptionFilterArgs, SubscriptionsTypes } from '../../../../../../application'

import {
    type BackendPath,
    ContainersListFetcher,
    type DockerContainerResponse,
    type DockerContainersResponse,
    type KubernetesContainerResponse,
    mapToStatusType,
    type OrchestratorResponse,
} from '../../../../../Connectware'
import { type BaseStrategyRequestArgs, BaseSubscriptionStrategy } from '../Base'

type RequestConfig<Path extends BackendPath> = Pick<BaseStrategyRequestArgs<Path, never, never>, 'path' | 'method' | 'capability'>
type Config = ValuesType<{ [Path in BackendPath]: RequestConfig<Path> }>

class HttpContainersListFetcher extends ContainersListFetcher {
    constructor (
        isDocker: Promise<boolean>,
        filter: SubscriptionFilterArgs,
        private readonly config: [containers: Config, inspect: Config],
        private readonly request: <R, D>(args: BaseStrategyRequestArgs<BackendPath, R, D>) => Promise<D>
    ) {
        super(isDocker, filter)
    }

    protected fetchContainersResponse (): Promise<DockerContainersResponse> {
        const [containers] = this.config
        return this.request({ ...(containers as typeof containers & RequestConfig<BackendPath>), mapper: (data: DockerContainersResponse) => data })
    }

    protected fetchContainerMetadata<T extends DockerContainerResponse | KubernetesContainerResponse> (name: string): Promise<T | null> {
        const [, inspect] = this.config
        return this.request({
            ...(inspect as typeof inspect & RequestConfig<BackendPath>),
            pathParams: [name],
            mapper: (data: T) => data,
        })
    }
}

abstract class ContainerStrategy<T extends keyof SubscriptionsTypes> extends BaseSubscriptionStrategy<T> {
    /** Cache of the orchestrator */
    private isDocker: Promise<boolean> | null = null

    protected abstract readonly containersConfig: [containers: Config, inspect: Config, orchestrator: Config]
    protected abstract readonly inspectConfig: Config

    /**
     * The mapper that maps the response of single retrieval
     * If left null, it will cause the `retrieveOne` function to yield a not implemented error
     */
    protected readonly mapSingleRetrieval:
        | ((id: string, data: DockerContainerResponse | KubernetesContainerResponse, status: StatusType) => SubscriptionsTypes[T])
        | null = null

    /**
     * Method name to fetch the containers
     * If left null, it will cause the `retrieveAll` function to yield a not implemented error
     */
    protected readonly fetchAllMethod: keyof PickByValueExact<(typeof ContainersListFetcher)['prototype'], () => Promise<SubscriptionsTypes[T][]>> | null = null

    override retrieveAll (filter: SubscriptionFilterArgs): Promise<SubscriptionsTypes[T][]> {
        const { fetchAllMethod } = this

        if (!fetchAllMethod) {
            /** Not operating in fetch list mode */
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Method not implemented', { method: 'retrieveAll', filter })
        }

        const [containers, inspect, orchestrator] = this.containersConfig

        /** Finally call if the docker is running */
        this.isDocker =
            this.isDocker ||
            this.retrieve({
                ...(orchestrator as typeof orchestrator & RequestConfig<BackendPath>),
                mapper: (data: OrchestratorResponse) => data.orchestrator === 'Docker',
            })

        /** Create fetcher to fetch the list */
        const fetcher = new HttpContainersListFetcher(this.isDocker, filter, [containers, inspect], (args) => this.retrieve(args))

        return fetcher[fetchAllMethod]() as Promise<SubscriptionsTypes[T][]>
    }

    override retrieveOne (id: string): Promise<SubscriptionsTypes[T]> {
        const { mapSingleRetrieval } = this

        if (!mapSingleRetrieval) {
            /** Not operating in fetch single mode */
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Method not implemented', { id, method: 'retrieveOne' })
        }

        return this.retrieve({
            ...(this.inspectConfig as typeof this.inspectConfig & RequestConfig<BackendPath>),
            pathParams: [id],
            mapper: (data: DockerContainerResponse | KubernetesContainerResponse) => {
                const status = mapToStatusType(data.State.Status?.toLowerCase())

                return mapSingleRetrieval(id, data, status)
            },
            handleNotFound: true,
        })
    }
}

export abstract class BaseCoreContainerStrategy<T extends keyof SubscriptionsTypes> extends ContainerStrategy<T> {
    protected readonly containersConfig: [containers: Config, inspect: Config, orchestrator: Config] = [
        /**
         * @deprecated this endpoint will no longer provide useful statuses
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        { capability: Capability.CORE_CONTAINERS_READ, method: 'GET', path: '/api/core-containers' },
        /**
         * @deprecated this endpoint will no longer provide useful statuses
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        { capability: Capability.CORE_CONTAINERS_READ, method: 'GET', path: '/api/core-containers/+/inspect' },
        { capability: Capability.CORE_CONTAINERS_READ, method: 'GET', path: '/api/core-containers/orchestrator' },
    ]

    protected readonly inspectConfig: Config = { capability: Capability.CORE_CONTAINER_READ, method: 'GET', path: '/api/core-containers/+/inspect' }
}

export abstract class BaseServiceContainerStrategy<T extends keyof SubscriptionsTypes> extends ContainerStrategy<T> {
    protected readonly containersConfig: [containers: Config, inspect: Config, orchestrator: Config] = [
        /**
         * @deprecated this endpoint will no longer provide useful statuses
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        { capability: Capability.SERVICE_CONTAINERS_READ, method: 'GET', path: '/api/containers' },
        /**
         * @deprecated this endpoint will no longer provide useful statuses
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        { capability: Capability.SERVICE_CONTAINERS_READ, method: 'GET', path: '/api/containers/+/inspect' },
        { capability: Capability.SERVICE_CONTAINERS_READ, method: 'GET', path: '/api/containers/orchestrator' },
    ]

    protected readonly inspectConfig: Config = { capability: Capability.SERVICE_CONTAINER_READ, method: 'GET', path: '/api/containers/+/inspect' }
}
