import type { ReadonlyRecord } from '../../../../../../utils'
import { areCybusServiceEquals, Capability, type CybusService } from '../../../../../../domain'

import { type BackendJsonResponseContent, type CybusServiceProxyInfo, type LinkedService, mapBaseService, mapToStatusType } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class ServicesStrategy extends BaseSubscriptionStrategy<'services'> {
    readonly requiredPermissions = [Capability.SERVICES_READ]

    protected override readonly areEquals = areCybusServiceEquals

    override retrieveAll (): Promise<CybusService[]> {
        return Promise.all([
            this.retrieve({
                capability: Capability.SERVICES_READ,
                method: 'GET',
                path: '/api/services/info',
                mapper: (data: CybusServiceProxyInfo[]) => data,
            }),
            this.retrieve({
                capability: Capability.SERVICES_READ,
                method: 'GET',
                path: '/api/services/all-links',
                mapper: (data: LinkedService[]) => data,
            }),
        ]).then(([info, links]) => {
            const indexed = links.reduce<ReadonlyRecord<LinkedService['id'], LinkedService['links']>>(
                (r, linkedService) => ({ ...r, [linkedService.id]: linkedService.links }),
                {}
            )

            return info
                .map((data) => {
                    /**
                     * @deprecated update code block so deviation is fetched externally
                     * @see https://cybusio.atlassian.net/browse/CC-1251
                     */
                    const isDeviated = data.isDeviated

                    /**
                     * @note currently, the 'state' field contains the value of currentState
                     * In the future, it will contains the targeState value instead
                     * @see https://cybusio.atlassian.net/browse/CC-1251
                     */
                    const currentStatus = mapToStatusType(data.state)
                    return { ...mapBaseService(data, currentStatus, isDeviated), links: indexed[data.id] || [] }
                })
                .sort((a, b) => a.id.localeCompare(b.id))
        })
    }

    override retrieveCount (): Promise<number> {
        return this.retrieve({
            capability: Capability.SERVICES_READ,
            method: 'GET',
            path: '/api/services/instances',
            mapper: (data: BackendJsonResponseContent<'/api/services/instances', 'get', 200>) => data.length,
        })
    }
}
