import type { ConnectwareErrorExtras, MfaRequiredAuthentication, SSOAuthenticationMethod } from '..'
import { ConnectwareError, ConnectwareErrorType } from '../Error'

export class SessionFromTokenNotFoundError extends ConnectwareError {
    static override is (e: unknown): e is SessionFromTokenNotFoundError {
        return e instanceof SessionFromTokenNotFoundError
    }

    constructor () {
        super(ConnectwareErrorType.AUTHENTICATION, 'Session not found')
    }
}

export class TokenExpiredError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION> {
    static override is (e: unknown): e is TokenExpiredError {
        return e instanceof TokenExpiredError
    }

    constructor () {
        super(ConnectwareErrorType.AUTHENTICATION, 'Token expired')
    }
}

export class MfaRequiredError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION, Readonly<{ secret: string }>> {
    static override is (e: unknown): e is MfaRequiredError {
        return e instanceof MfaRequiredError
    }

    constructor (secret: string) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Multi-factor authentication required', { secret })
    }

    get secret (): string {
        return this.extras.secret
    }
}

export class MfaSetupRequiredError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION, MfaRequiredAuthentication> {
    static override is (e: unknown): e is MfaSetupRequiredError {
        return e instanceof MfaSetupRequiredError
    }

    constructor (authentication: MfaRequiredAuthentication) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Multi-factor authentication setup required', authentication)
    }
}

type SSOInvalidExtras = Readonly<{ type: SSOAuthenticationMethod, parameters: ConnectwareErrorExtras<ConnectwareError> }>

export class SSOInvalidSecretError extends ConnectwareError<ConnectwareErrorType.AUTHENTICATION, SSOInvalidExtras> {
    static override is (e: unknown): e is SSOInvalidSecretError {
        return e instanceof SSOInvalidSecretError
    }

    constructor (extras: SSOInvalidExtras) {
        super(ConnectwareErrorType.AUTHENTICATION, 'Invalid client secret provided', extras)
    }
}
