import React, { type FC, useEffect } from 'react'
import { Box, Button, CircularProgress, type SxProps, type Theme, Typography } from '@mui/material'

import type { ReadonlyRecord } from '../../../utils'
import { ConnectwareError, selectSupportedSSOs, SSOAuthenticationMethod, Translation } from '../../../domain'

import { useAsyncCallback } from '../Callback'
import { FormattedTranslation } from '../Internationalization'
import { useAppState, useAppUsecase } from '../State'
import { MSIcon } from '../common'
import { LoginValidationError } from './Common'

const useEntraIdSectionStyle: SxProps<Theme> = { my: 2 }
const useUserNameStyle: SxProps<Theme> = { display: 'flex', alignItems: 'center', width: '100%', position: 'relative', color: 'white', mt: 1 }
const useUserNameLabelStyle: SxProps<Theme> = { mx: 2, textTransform: 'uppercase' }
const useUserNameLineStyle: SxProps<Theme> = { flexGrow: 1, height: '1px', backgroundColor: '#ffffff3b' }
const useSignInButtonStyle: SxProps<Theme> = { borderColor: 'text.contrast', py: 1, mb: 1, background: '#0D486D', '&:hover': { borderColor: 'text.contrast' } }
const useLoadingIndicatorStyle: SxProps<Theme> = { display: 'flex', justifyContent: 'center', width: '100%' }
const useErrorStyle: SxProps<Theme> = { my: 2 }

const ssoIconMap: ReadonlyRecord<SSOAuthenticationMethod, FC> = {
    [SSOAuthenticationMethod.MICROSOFT_ENTRA_ID]: MSIcon,
}

export const LoginWithSSO: FC = () => {
    const usecase = useAppUsecase('ssoLoginUsecase')
    const supportedSSOTypes = useAppState(selectSupportedSSOs)

    // eslint-disable-next-line no-void
    useEffect(() => void usecase.fetchSupported(), [usecase])

    const [redirect, isRedirecting, result] = useAsyncCallback((type: SSOAuthenticationMethod) => usecase.redirectToAuthenticationUrl(type), [usecase])

    const isLoading = supportedSSOTypes === null
    const hasError = ConnectwareError.is(supportedSSOTypes)

    return (
        <>
            {isLoading && (
                <Box sx={useLoadingIndicatorStyle}>
                    <CircularProgress id="sso-circular-loading" size={20} color="info" />
                </Box>
            )}

            {hasError && (
                <LoginValidationError id="sso-error-message">
                    <FormattedTranslation id={Translation.SSO_STATUS_FETCHING_ERROR} />
                </LoginValidationError>
            )}

            {Array.isArray(supportedSSOTypes) &&
                supportedSSOTypes.map((type) => {
                    const Icon = ssoIconMap[type]

                    return (
                        <Box key={type} id={`${type}-section`} sx={useEntraIdSectionStyle}>
                            <Button
                                id={`sign-in-with-${type}-button`}
                                sx={useSignInButtonStyle}
                                startIcon={<Icon />}
                                onClick={() => redirect(type)}
                                variant="outlined"
                                disabled={isRedirecting}
                                fullWidth
                            >
                                <Typography color="white" textTransform="initial" variant="body2">
                                    <FormattedTranslation id={Translation.SIGN_IN_WITH_SSO} values={{ type }} />
                                </Typography>
                            </Button>

                            {!isRedirecting && ConnectwareError.is(result) && (
                                <LoginValidationError sx={useErrorStyle} id="redirect-to-sso-error-message">
                                    <FormattedTranslation id={Translation.SSO_REDIRECTING_ERROR} values={{ type }} />
                                </LoginValidationError>
                            )}

                            <Box sx={useUserNameStyle}>
                                <Box sx={useUserNameLineStyle} />
                                <Typography sx={useUserNameLabelStyle} variant="body2">
                                    <FormattedTranslation id={Translation.OR_USE_USERNAME} />
                                </Typography>
                                <Box sx={useUserNameLineStyle} />
                            </Box>
                        </Box>
                    )
                })}
        </>
    )
}
