import { areAgentEquals, Capability, type CybusAgent } from '../../../../../../domain'
import { type AgentResponse, mapAgents } from '../../../../../Connectware'

import { BaseSubscriptionStrategy } from '../Base'

export class AgentsStrategy extends BaseSubscriptionStrategy<'agents'> {
    readonly requiredPermissions = [Capability.AGENTS_READ]

    protected override readonly areEquals = areAgentEquals

    override retrieveAll (): Promise<CybusAgent[]> {
        return this.retrieve({
            capability: Capability.AGENTS_READ,
            method: 'GET',
            /**
             * @deprecated this endpoint will no longer provide useful statuses
             * @todo consider using another endpoint (/api/v2/resource-runners)
             * @see https://cybusio.atlassian.net/browse/CC-2118
             * @see https://cybusio.atlassian.net/browse/CC-2201 (FE ticket)
             */
            path: '/api/system/agents',
            mapper: (agents: AgentResponse[]) => {
                /**
                 * @deprecated update code block so status is fetched externally
                 * @see https://cybusio.atlassian.net/browse/CC-2118
                 *
                 * response of /api/v2/resource-runners: data: [{ "runner": { "name": "protocol-mapper" }, "status": "online"}]
                 */
                const statusMap = new Map(agents.map((s) => [s.name, s.status]))

                return mapAgents(agents, statusMap, (a) => [a.name, a])
            },
        })
    }
}
