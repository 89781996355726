import { type ArrayIntersection, isArray, objectKeys, type ReadonlyRecord } from '../../../../utils'
import type { CybusDetailedCoreContainer, CybusDetailedServiceContainer, StatusType } from '../../../../domain'

import { type DockerContainerResponse, type KubernetesContainerResponse, mapResourceNames, safelyMapPlainProperties } from '../..'

export const mapDetailedCoreContainer = (
    id: string,
    name: string,
    container: KubernetesContainerResponse | DockerContainerResponse,
    status: StatusType
): CybusDetailedCoreContainer => {
    const { Created, Mounts, Config, HostConfig } = container
    const { Image, WorkingDir, Labels, ExposedPorts = {}, Env } = Config

    return {
        id,
        name,
        created: Created ? new Date(Created) : null,
        volumes: (Mounts as ArrayIntersection<typeof Mounts>).reduce<string[]>((volumes, v) => (v.Name ? [...volumes, v.Name] : volumes), []),
        status,
        config: {
            image: Image,
            workingDir: WorkingDir,
            author: Labels.author || null,
            vendor: Labels.vendor || null,
            capAdd: isArray(HostConfig.CapAdd) ? HostConfig.CapAdd.map(String) : HostConfig.CapAdd ? String(HostConfig.CapAdd) : null,
            exposedPorts: objectKeys<ReadonlyRecord<string, unknown>>(ExposedPorts),
            privileged: HostConfig.Privileged || false,
        },
        variables: safelyMapPlainProperties(
            Env.reduce<CybusDetailedCoreContainer['variables']>((r, variable) => {
                const [name, stringValue] = variable.split('=')
                return name === undefined || stringValue === undefined ? r : { ...r, [name]: stringValue }
            }, {})
        ),
    }
}

export const mapDetailedServiceContainer = (
    id: string,
    container: KubernetesContainerResponse | DockerContainerResponse,
    status: StatusType
): CybusDetailedServiceContainer => {
    const [service, name] = mapResourceNames(id)
    return { ...mapDetailedCoreContainer(id, name, container, status), service }
}
