import { type AuthenticationInformation, ConnectwareError, ConnectwareErrorType, SSOAuthenticationMethod, SSOInvalidSecretError } from '../../domain'
import type { BrowserService, LoggerService } from '../../application'

export class HTML5BrowserService implements BrowserService {
    protected targetDocument: Pick<Document, 'cookie'> = document

    protected targetWindow: Pick<Window, 'open'> = window

    protected targetLocation: Pick<Location, 'reload' | 'search' | 'replace' | 'href'> = window.location

    protected targetHistory: Pick<History, 'pushState'> = window.history

    protected targetClipboard: Pick<Clipboard, 'writeText'> = navigator.clipboard

    constructor (private readonly logger: LoggerService, private readonly origin: URL['origin'], private readonly cookiePaths: string[]) {}

    private setAuthCookie (token: string | null, expiresAt: Date | null, path: string): void {
        const values: ([key: string, value: string] | [key: string])[] = [
            ['X-Auth-Token', encodeURIComponent(token ?? '')],
            ['path', path],
            ['SameSite', 'strict'],
            ['Secure'],
            ['Host', this.origin],
        ]

        if (expiresAt) {
            values.push(['expires', expiresAt.toUTCString()])
        }

        this.targetDocument.cookie = values.map((pair) => pair.join('=')).join('; ')
    }

    private getUrlParameters (): URLSearchParams {
        return new URLSearchParams(this.targetLocation.search)
    }

    private getUrlParameter (name: string): string | null {
        return this.getUrlParameters().get(name)
    }

    isInspecting (): boolean {
        return this.getUrlParameter('inspection') !== null
    }

    redirect (url: URL): void {
        this.targetLocation.replace(url.toString())
    }

    getRedirectionURL (): URL | null {
        const redirectParameter = this.getUrlParameter('redirect')
        return redirectParameter ? new URL(redirectParameter, this.origin) : null
    }

    extractURLAuthentication (): string | ConnectwareError | null {
        const parameters = this.getUrlParameters()

        const apiToken = parameters.get('apiToken')
        if (apiToken) {
            /**
             * Authentication is present, so just yield it
             */
            return apiToken
        }

        if (parameters.get('ssoRedirect') === String(true)) {
            const urlParameters = Object.fromEntries(Array.from(this.getUrlParameters()))

            if (parameters.get('error') === 'invalid_client') {
                return new SSOInvalidSecretError({ type: SSOAuthenticationMethod.MICROSOFT_ENTRA_ID, parameters: urlParameters })
            }

            return new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could no authenticate with SSO', urlParameters)
        }

        return null
    }

    removeURLAuthentication (): void {
        const url = new URL(this.targetLocation.href)
        url.search = ''
        this.targetHistory.pushState({}, '', url)
    }

    authenticate (info: AuthenticationInformation | null): void {
        const token = info?.token ?? null
        const expiresAt = info?.expiresAt ?? null

        for (const path of this.cookiePaths) {
            this.setAuthCookie(token, expiresAt, path)
        }
    }

    reload (): void {
        this.targetLocation.reload()
    }

    open (url: URL, name: string | null): void {
        const ref = url.toString()
        this.targetWindow.open(ref, name || ref)
    }

    sendEmail (target: string, subject: string | null): void {
        const url = new URL(`mailto:${target}`)

        if (subject) {
            url.searchParams.append('subject', subject)
        }

        this.open(url, null)
    }

    copy (value: unknown): string | null {
        let data: string | null = null

        switch (typeof value) {
            case 'string':
                data = value
                break
            default:
                /** Just quietly log the issue */
                this.logger.error(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Only string values are supported to be copied', { value }))
        }

        if (data === null) {
            return null
        }

        this.targetClipboard.writeText(data).catch(({ message, code }: DOMException) => {
            /** Just quietly log the issue */
            this.logger.error(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not write to clipboard', { message, code, value }))
        })

        return data
    }
}
